import { RoutePermissions } from 'router';
import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  error: any;
  success: boolean;
  userId: string | null;
  userName: string | null;
  token: string | null;
  permissions: RoutePermissions[];
}

export const initialState: AuthState = {
  loading: false,
  error: null,
  success: false,
  userId: localStorage.getItem('__userId') || null,
  userName: localStorage.getItem('__userName') || null,
  token: localStorage.getItem('__token') || null,
  permissions: JSON.parse(localStorage.getItem('__permissions') || '[]'),
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    registerGuest(state, action) {
      state.loading = true;
    },
    registerGuestSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userName = action.payload.userName;
    },
    registerGuestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.userName = null;
    },
    registerGuestFailureBecauseAlready(state, action) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.userName = action.payload;
    },

    forgotPassword(state, action) {
      state.loading = true;
    },
    forgotPasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userName = action.payload.userName;
    },
    forgotPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.userName = null;
    },

    verificationAccount(state, action) {
      state.loading = true;
    },
    verificationAccountSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    verificationAccountFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    createPassword(state, action) {
      state.loading = true;
    },
    createPasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    createPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    login(state, action) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userId = action.payload.id;
      state.userName = action.payload.userName;
      state.token = action.payload.token;
      state.permissions = [RoutePermissions.AUTH_CLIENT];

      localStorage.setItem('__userId', state.userId || '');
      localStorage.setItem('__userName', state.userName || '');
      localStorage.setItem('__token', action.payload.token);
      localStorage.setItem(
        '__permissions',
        JSON.stringify([RoutePermissions.AUTH_CLIENT]),
      );
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    logout(state) {
      state.loading = true;
    },
    logoutSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userId = null;
      state.userName = null;
      state.token = null;
      state.permissions = [];

      localStorage.removeItem('__userId');
      localStorage.removeItem('__userName');
      localStorage.removeItem('__token');
      localStorage.removeItem('__permissions');
    },
    logoutFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AuthSlice;
