import { lazyLoad } from 'utils/loadable';
import { LoaderFallBack } from 'app/components/Loading';

export const Home = lazyLoad(
  () => import('app/containers/Home'),
  module => module.default,
  LoaderFallBack(),
);

export const Introduce = lazyLoad(
  () => import('app/containers/Introduce'),
  module => module.default,
  LoaderFallBack(),
);

export const Flow = lazyLoad(
  () => import('app/containers/Flow'),
  module => module.default,
  LoaderFallBack(),
);

export const LoginPage = lazyLoad(
  () => import('app/containers/LoginPage'),
  module => module.LoginPage,
  LoaderFallBack(),
);

export const RegisterNumber = lazyLoad(
  () => import('app/containers/RegisterNumber'),
  module => module.RegisterNumber,
  LoaderFallBack(),
);

export const TermsOfService = lazyLoad(
  () => import('app/containers/TermsOfServices'),
  module => module.TermsOfService,
  LoaderFallBack(),
);

export const PrivatePolices = lazyLoad(
  () => import('app/containers/PrivatePolices'),
  module => module.PrivatePolices,
  LoaderFallBack(),
);

export const UsageRules = lazyLoad(
  () => import('app/containers/UsageRules'),
  module => module.UsageRules,
  LoaderFallBack(),
);

export const FromPatient = lazyLoad(
  () => import('app/containers/InfoPatient'),
  module => module.FromPatient,
  LoaderFallBack(),
);

export const HospitalFee = lazyLoad(
  () => import('app/containers/HospitalFeePayment'),
  module => module.HospitalFeePayment,
  LoaderFallBack(),
);

export const PaymentHistory = lazyLoad(
  () => import('app/containers/PaymentHistory'),
  module => module.PaymentHistory,
  LoaderFallBack(),
);

export const Bill = lazyLoad(
  () => import('app/containers/Bill'),
  module => module.Bill,
  LoaderFallBack(),
);
