/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { HOSPITAL_INFO } from 'constants/app.constants';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as sharedSelectors from 'store/shared/selectors';

export function Footer() {
  const hospitalCode = useSelector(sharedSelectors.selectHospitalCode);
  return (
    <div
      data-test="animation"
      className="animated fadeIn style_footer__2ofvm"
      style={{
        animationName: 'fadeIn',
        visibility: 'visible',
        animationIterationCount: 1,
      }}
    >
      <div className="style_footer_inner__3bmPJ">
        <div data-test="container" className="container">
          <div data-test="row" className="row">
            <div data-test="col" className="col-lg-2">
              <div className="style_footer_item__2rIfV">
                <a href="">
                  <div className="lazyload-wrapper ">
                    <img
                      src={HOSPITAL_INFO[hospitalCode].logoUrl}
                      className="img-fluid style_logo_footer__XtemS"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div data-test="col" className="col-lg-5">
              <div className="style_footer_item__2rIfV">
                <ul
                  data-test="list-group"
                  className="list-group style_item_info__3c0lh"
                >
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <span className="style_textPKH__2bo7W">
                      <strong className="text-uppercase">
                        {HOSPITAL_INFO[hospitalCode].name}
                      </strong>
                    </span>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <span>Địa chỉ: {HOSPITAL_INFO[hospitalCode].address}</span>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a
                      href={HOSPITAL_INFO[hospitalCode].website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website: {HOSPITAL_INFO[hospitalCode].website}
                    </a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <span>Email: {HOSPITAL_INFO[hospitalCode].email}</span>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <span>
                      Điện thoại: {HOSPITAL_INFO[hospitalCode].hotline}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div data-test="col" className="col-lg-2 d-none d-lg-block">
              <div className="style_footer_item__2rIfV">
                <ul
                  data-test="list-group"
                  className="list-group style_item_info__3c0lh"
                >
                  {/* <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="lien-he">Liên hệ</a>
                  </li> */}
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="dieu-khoan-dich-vu">Điều khoản dịch vụ</a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="chinh-sach-bao-mat">Chính sách bảo mật</a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="quy-dinh-su-dung">Quy định sử dụng</a>
                  </li>
                </ul>
              </div>
            </div>
            <div data-test="col" className="col-lg-3">
              <div className="style_footer_item__2rIfV">
                <ul
                  data-test="list-group"
                  className="list-group style_item_info__3c0lh style_icons__3sEtr"
                >
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a
                      href="#"
                      target="_blank"
                      title="Bộ công thương"
                      rel="noopener noreferrer"
                    >
                      <div className="lazyload-wrapper ">
                        <img
                          src={require('./images/bocongthuong1.ce5cd70e.svg')}
                        />
                      </div>
                    </a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a
                      href=""
                      target="_blank"
                      title="Bộ công thương"
                      rel="noopener noreferrer"
                    >
                      <div className="lazyload-wrapper ">
                        <img
                          src={require('./images/bocongthuong2.d032db55.svg')}
                        />
                      </div>
                    </a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <div className="lazyload-wrapper ">
                        <img
                          src={require('./images/googleplay.142f41c8.svg')}
                        />
                      </div>
                    </a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <div className="lazyload-wrapper ">
                        <img src={require('./images/appstore.cdb12f48.svg')} />
                      </div>
                    </a>
                  </li>
                  <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a
                      href=""
                      title="DMCA.com Protection Status"
                      className="dmca-badge"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require('./images/dmca_protected_sml_120m.png')}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="style_footer_copy_right__3d8A7">
        <div data-test="container" className="container">
          <div data-test="row" className="row">
            <div data-test="col" className="col-12">
              <div className="style_footer_item__2rIfV">
                © {new Date().getFullYear()} - Tekmedi
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
