/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectSharedState = (state: RootState) => state.shared || initialState;

export const selectLoading = createSelector(
  [selectSharedState],
  shared => shared.loading,
);

export const selectError = createSelector(
  [selectSharedState],
  shared => shared.error,
);

export const selectSuccess = createSelector(
  [selectSharedState],
  shared => shared.success,
);

export const selectHospitalCode = createSelector(
  [selectSharedState],
  shared => shared.hospitalCode,
);

export const selectSpecialists = createSelector(
  [selectSharedState],
  shared => shared.specialists,
);

export const selectDepartments = createSelector(
  [selectSharedState],
  shared => shared.departments,
);

export const selectListNumberOnline = createSelector(
  [selectSharedState],
  shared => shared.listNumberOnline,
);
