export const DEFAULT_FORMAT_DATE = 'dd/MM/yyyy';
export const MOMENT_RENDER = 'DD-MM-YYYY';
export const MOMENT_CALL_API = 'yyyy-MM-DD';
export const MOMENT_HOUR_RENDER = 'HH:mm';
export const MOMENT_HOUR_CALL_API = 'HH:mm:ss';
export const DEFAULT_FORMAT_OUT_TIME = 'DD/MM/YYYY';
export const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';
export const MESSAGE_CONST = {
  NOT_ACCOUNT:
    'Bạn chưa có tài khoản TEKMEDI. Bấm tiếp tục để tiến hành đăng ký',
};

export const HOSPITAL_TYPE = [
  {
    code: 'BVCR',
    name: 'Bệnh viện Chợ Rẫy',
  },
  {
    code: 'BVTN',
    name: 'Bệnh viện Thống Nhất',
  },
];

export const PATIENT_TYPE = [
  {
    id: 'bảo hiểm y tế',
    name: 'Khám bảo hiểm y tế',
  },
  {
    id: 'dịch vụ',
    name: 'Khám dịch vụ',
  },
];

export const HOSPITAL_NAME = {
  BVCR: 'Bệnh viện chợ rẫy',
  BVTN: 'Bệnh viện thống nhất',
};

export const TREATMENT_NAME = {
  0: 'Ngoại trú',
  1: 'Nội trú',
};

export const PAY_PROVIDER = {
  0: {
    name: 'Thẻ Tekmedi',
  },
  1: {
    name: 'Ví Momo',
  },
  2: {
    name: 'Thẻ Vietcombank',
  },
  3: {
    name: 'Khác',
  },
};

export const STATUS = {
  0: {
    name: 'Thành công',
    color: 'green',
  },
  1: {
    name: 'Thất bại',
    color: 'red',
  },
};

export const HOSPITAL_INFO = {
  default: {
    name: 'Bệnh viện Chợ Rẫy',
    address: '201B Nguyễn Chí Thanh, Phường 12, Quận 5, Thành phố Hồ Chí Minh',
    website: 'http://choray.vn',
    email: 'bvchoray@choray.vn',
    hotline: '(84-028) 3855 4137',
    logoUrl: '/bvcr_logo.svg',
  },
  BVCR: {
    name: 'Bệnh viện Chợ Rẫy',
    address: '201B Nguyễn Chí Thanh, Phường 12, Quận 5, Thành phố Hồ Chí Minh',
    website: 'http://choray.vn',
    email: 'bvchoray@choray.vn',
    hotline: '(84-028) 3855 4137',
    logoUrl: '/bvcr_logo.svg',
  },
  BVTN: {
    name: 'Bệnh viện Thống Nhất',
    address: 'Số 1 Lý Thường Kiệt, Phường 7, Quận Tân Bình, TP Hồ Chí Minh',
    website: 'http://bvtn.org.vn/',
    email: 'thongnhathospital@bvtn.org.vn',
    hotline:
      '028 3869 0277 | Kế Hoạch Tổng Hợp: 028 38642 142 | Fax: 028 3865 6715',
    logoUrl: '/bvtn_logo.png',
  },
};
