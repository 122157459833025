import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface SharedState {
  loading: boolean;
  error: any;
  success: boolean;
  hospitalCode: string;
  specialists: any[];
  departments: any[];
  listNumberOnline: any[];
}

export const initialState: SharedState = {
  loading: false,
  error: null,
  success: false,
  hospitalCode: localStorage.getItem('__hospitalCode') || 'default',
  specialists: [],
  departments: [],
  listNumberOnline: [],
};

const SharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    changeHospitalCode(state, action) {
      state.hospitalCode = action.payload || 'BVCR';
      localStorage.setItem('__hospitalCode', action.payload || 'BVCR');
    },

    fetchSpecialists(state) {
      state.loading = true;
      state.specialists = [];
    },
    fetchSpecialistsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.specialists = action.payload;
    },
    fetchSpecialistsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    fetchDepartments(state, action) {
      state.loading = true;
      state.departments = [];
    },
    fetchDepartmentsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.departments = action.payload;
    },
    fetchDepartmentsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    fetchTimeSlot(state, action) {
      state.loading = true;
      state.listNumberOnline = [];
    },
    fetchTimeSlotSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.listNumberOnline = action.payload?.list_number_online || [];
    },
    fetchTimeSlotFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = SharedSlice;
