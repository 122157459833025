import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'store/core/@reduxjs/redux-injectors';
import * as SharedSlice from 'store/shared/slice';
import * as sharedSelectors from 'store/shared/selectors';
import styled from 'styled-components';

const HospitalTitle = styled.div`
  text-align: center;
  border-radius: 20px;
  background-color: #eef1f5;
  padding: 2px 20px;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    background-color: #df27366a;
  }
`;

export default function HospitalSelector() {
  useInjectReducer({ key: SharedSlice.sliceKey, reducer: SharedSlice.reducer });
  const dispatch = useDispatch();
  const hospitalCode = useSelector(sharedSelectors.selectHospitalCode);
  const changeHospitalCode = code => {
    dispatch(SharedSlice.actions.changeHospitalCode(code));
  };

  return (
    <Dialog open={!hospitalCode || hospitalCode === 'default'} maxWidth="xs">
      <DialogContent>
        <div className="row">
          <div className="col-12 h5"> CHỌN BỆNH VIỆN</div>
          <div className="col-12">
            <HospitalTitle onClick={() => changeHospitalCode('BVCR')}>
              Bệnh viện Chợ Rẫy
            </HospitalTitle>
          </div>
          <div className="col-12">
            <HospitalTitle onClick={() => changeHospitalCode('BVTN')}>
              Bệnh viện Thống nhất
            </HospitalTitle>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
