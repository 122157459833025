import React from 'react';
import moment from 'moment';

export const isDev: boolean = process.env.NODE_ENV === 'development';
export interface AppState {
  loading: boolean;
  provinces: any[];
  hospitals: any[];
  hospital: any;
  rooms: any[];
  room: any;
  specials: any[];
  special: any;
  user: any;
  error: any;
  isLogged: boolean;
  openDraw: boolean;
  registerDate: any;
}

export enum ActionType {
  LOGIN = 'LOGIN',
  LOGIN_OK = 'LOGIN_OK',
  LOGIN_FAIL = 'LOGIN_FAIL',
  SET_PROVINCE = 'SET_PROVINCE',
  SET_HOSPITALS = 'SET_HOSPITALS',
  SET_HOSPITAL_ITEM = 'SET_HOSPITAL_ITEM',
  SET_SPECIALIST = 'SET_SPECIALIST',
  SET_SPECIAL = 'SET_SPECIAL',
  SET_ROOM = 'SET_ROOM',
  SET_ROOMS = 'SET_ROOMS',
  SET_DATE = 'SET_DATE',
  TOGGLE_DRAW = 'TOGGLE_DRAW',
}

export interface Action {
  type: ActionType;
  payload?: any;
}

const initialState: AppState = {
  loading: false,
  provinces: [],
  hospitals: [],
  hospital: {},
  specials: [],
  special: {},
  rooms: [],
  room: {},
  user: {},
  registerDate: moment()
    .add(+3, 'days')
    .endOf('day'),
  error: null,
  isLogged: false,
  openDraw: false,
};

export const reducer = (
  state: AppState = initialState,
  { type, payload }: Action,
): AppState => {
  switch (type) {
    case ActionType.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case ActionType.LOGIN_OK:
      return {
        ...state,
        user: payload,
        loading: false,
        isLogged: true,
      };
    case ActionType.LOGIN_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
        isLogged: false,
      };
    case ActionType.SET_PROVINCE:
      return {
        ...state,
        provinces: payload,
      };
    case ActionType.SET_HOSPITALS:
      return {
        ...state,
        hospitals: payload,
      };
    case ActionType.SET_HOSPITAL_ITEM:
      return {
        ...state,
        hospital: payload,
      };
    case ActionType.SET_SPECIALIST:
      return {
        ...state,
        specials: payload,
      };
    case ActionType.SET_SPECIAL:
      return {
        ...state,
        special: payload,
      };
    case ActionType.SET_ROOMS:
      return {
        ...state,
        rooms: payload,
      };
    case ActionType.SET_ROOM:
      return {
        ...state,
        room: payload,
      };
    case ActionType.SET_DATE:
      return {
        ...state,
        registerDate: payload,
      };
    case ActionType.TOGGLE_DRAW:
      return {
        ...state,
        openDraw: !state.openDraw,
      };
    default:
      return state;
  }
};

const AppContext: React.Context<any> = React.createContext<any>(initialState);

export const AppProvider = (props: any) => {
  const store = React.useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={store}>{props.children}</AppContext.Provider>
  );
};

export const AppConsumer: React.Consumer<any> = AppContext.Consumer;

export default AppContext;
