/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest, configRequest } from 'utils/request';
import * as _ from 'lodash';

export const API_MAIN = process.env.REACT_APP_API_BASE;
export const API_AUTH = process.env.REACT_APP_API_AUTH;

export const APIRoutes = {
  PROVINCES: 'api/Location/all',
  SPECIALS: 'api/Online/specialist/all',
  SPECIALS_DPT: 'api/Online/specialist/department',
  TIMESLOTS: 'api/Online/time-slot',
  HOSPITAL: 'api/Hospital',
  REGISTER: 'api/Online/register',
  GET_BOOKING: 'api/Online/patient',
  REGISTER_GUEST: '/api/User/tekpay/register-guest',
  VERIFICATION_ACCOUNT: '/api/User/tekpay/verification-account',
  CREATE_PASSWORD: '/api/User/tekpay/sign-in',
  LOGIN: '/api/User/token',
  LOGOUT: '/api/User/tekpay/logout',
  SPECIALIST_ALL: '/api/Online/specialist/all',
  SPECIALIST_DEPARTMENT: '/api/Online/specialist/department/',
  TIME_SLOT: '/api/Online/time-slot',
  TRANSACTIONS: '/api/Transaction/Transactions',
  TRANSACTION_DETAIL: '/api/Transaction/Transactions',
  RECEPTION: '/api/Reception/user',
};

export const defaultRegType = 'online';

export class HttpService {
  request: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getProvinces = (): Promise<any> => {
    return this.request.get(APIRoutes.PROVINCES);
  };

  public getHospitals = (): Promise<any> => {
    return this.request.get(APIRoutes.HOSPITAL);
  };

  public getSpecialList = (hospital_code: string): Promise<any> => {
    return this.request.post(APIRoutes.SPECIALS, {
      hospital_code,
      type: defaultRegType,
    });
  };

  public getSpecialDept = ({ hospital_code, special_code }): Promise<any> => {
    return this.request.post(APIRoutes.SPECIALS_DPT + '/' + special_code, {
      hospital_code,
      type: defaultRegType,
    });
  };

  public getTimeSlots = (regInfo: any): Promise<any> => {
    return this.request.post(APIRoutes.TIMESLOTS, {
      ...configRequest(regInfo),
      type: defaultRegType,
    });
  };

  public registerOnline = (patientInfo: any): Promise<any> => {
    return this.request.post(APIRoutes.REGISTER, {
      ...patientInfo,
      user_Name: 'apptekpay',
      password: '12345',
      data_Sign: '',
      type: 'online',
      payment_Type: 0,
      hospital_Id: '08c89148-b2f7-49c6-946f-622de1db9433',
      switch_Board_Id: '3df8b493-ad4c-46bf-bd0a-e3e839781852',

      passport: '',
      id_issue_date: '',
      id_issue_place: '',
    });
  };

  public getBookingCode = ({ code, hospitalCode }) => {
    return this.request.post(APIRoutes.GET_BOOKING + '/' + code, {
      hospital_code: hospitalCode,
      type: defaultRegType,
    });
  };

  public registerGuest = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.REGISTER_GUEST, data);
  };

  public verificationAccount = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.VERIFICATION_ACCOUNT, data);
  };

  public createPassword = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.CREATE_PASSWORD, data);
  };

  public login = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.LOGIN, data);
  };

  public logout = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.LOGOUT, data);
  };

  public specialistAll = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.SPECIALIST_ALL, data);
  };

  public specialistDepartment = (
    specialistCode: string,
    data: any,
  ): Promise<any> => {
    return this.request.post(
      APIRoutes.SPECIALIST_DEPARTMENT + specialistCode,
      data,
    );
  };

  public timeSlot = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.TIME_SLOT, data);
  };

  public transactions = (userId: string, data: any): Promise<any> => {
    return this.request.get(`${APIRoutes.TRANSACTIONS}/${userId}`, data);
  };

  public transactionDetail = (
    transactionId: string,
    data: any,
  ): Promise<any> => {
    return this.request.post(
      `${APIRoutes.TRANSACTION_DETAIL}/${transactionId}`,
      data,
    );
  };

  public reception = (data: any): Promise<any> => {
    return this.request.post(APIRoutes.RECEPTION, data);
  };
}
