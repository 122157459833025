import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'antd/dist/antd.css';
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/theme/theme.style.css';
import './styles/custom.css';
import './styles/temofservices.css';
import './locales/i18n';
import { App } from 'app';
import { Provider } from 'react-redux';
import { RootStore } from 'store/configureStore';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AppProvider } from 'context';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <BrowserRouter>
    <Provider store={RootStore}>
      <AppProvider>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transitionDuration={{ enter: 300, exit: 200 }}
          autoHideDuration={2000}
        >
          <HelmetProvider>
            <Component />
          </HelmetProvider>
        </SnackbarProvider>
      </AppProvider>
    </Provider>
  </BrowserRouter>
);

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

serviceWorker.unregister();
