/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FunctionComponent } from 'react';
import * as Router from 'router/lazyRouting';

export interface AppRoute {
  path: string;
  Component: FunctionComponent;
  category: string;
  permission?: RoutePermissions;
  role?: RouteRoles;
}

export enum RouteRoles {
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  INTRODUCE = 'INTRODUCE',
  FLOW = 'FLOW',
  //REGISTER_NUMBER = 'REGISTER_NUMBER',
  TERM_OF_SERVICE = 'TERM_OF_SERVICE',
  PRIVATE_POLICIES = 'PRIVATE_POLICIES',
  USAGE_RULES = 'USAGE_RULES',
  FROM_PATIENT = 'FROM_PATIENT',
  HOSPITAL_FEE = 'HOSPITAL_FEE',
  PAYMENT_HISTORY = 'PAYMENT_HISTORY',
  BILL = 'BILL',
}

export enum RoutePermissions {
  CLIENT = 'CLIENT.INDEX',
  AUTH_CLIENT = 'AUTH_CLIENT.INDEX',
}

export enum RouteCategory {
  HOME = 'Trang Chủ',
  LOGIN = 'Đăng nhập',
  INTRODUCE = 'Giới thiệu',
  FLOW = 'Quy trình',
  //REGISTER_NUMBER = 'Đăng ký số thứ tự',
  TERM_OF_SERVICE = 'Điều khoản dịch vụ',
  PRIVATE_POLICIES = 'Chính sách bảo mật',
  USAGE_RULES = 'Quy định sử dụng',
  FROM_PATIENT = 'Đăng ký khám bệnh',
  HOSPITAL_FEE = 'Thanh toán Viện phí',
  PAYMENT_HISTORY = 'Lịch sử Thanh toán viện phí',
  BILL = 'Hoá đơn điện tử',
}

export const AppRouting: AppRoute[] = [
  {
    path: '/',
    Component: Router.Home,
    category: RouteCategory.HOME,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.HOME,
  },
  {
    path: '/gioi-thieu',
    Component: Router.Introduce,
    category: RouteCategory.INTRODUCE,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.INTRODUCE,
  },
  {
    path: '/quy-trinh',
    Component: Router.Flow,
    category: RouteCategory.FLOW,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.FLOW,
  },
  {
    path: '/dieu-khoan-dich-vu',
    Component: Router.TermsOfService,
    category: RouteCategory.TERM_OF_SERVICE,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.TERM_OF_SERVICE,
  },
  {
    path: '/chinh-sach-bao-mat',
    Component: Router.PrivatePolices,
    category: RouteCategory.PRIVATE_POLICIES,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.PRIVATE_POLICIES,
  },
  {
    path: '/quy-dinh-su-dung',
    Component: Router.UsageRules,
    category: RouteCategory.USAGE_RULES,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USAGE_RULES,
  },
  {
    path: '/dang-nhap',
    Component: Router.LoginPage,
    category: RouteCategory.LOGIN,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.LOGIN,
  },
  {
    path: '/dang-ky-kham-benh',
    Component: Router.FromPatient,
    category: RouteCategory.FROM_PATIENT,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.FROM_PATIENT,
  },
  {
    path: '/thanh-toan-vien-phi',
    Component: Router.HospitalFee,
    category: RouteCategory.HOSPITAL_FEE,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.HOSPITAL_FEE,
  },
  {
    path: '/lich-su-thanh-toan-vien-phi',
    Component: Router.PaymentHistory,
    category: RouteCategory.PAYMENT_HISTORY,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.PAYMENT_HISTORY,
  },
  {
    path: '/hoa-don-dien-tu',
    Component: Router.Bill,
    category: RouteCategory.BILL,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.BILL,
  },
];
