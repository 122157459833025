/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { HttpService } from 'services/api.service';
import { configResponse } from 'utils/request';

const http = new HttpService();

export function useTekProvince() {
  const [loadProvince, setLoad] = React.useState(false);
  const [provinceList, setProvinces] = React.useState([]);

  React.useEffect(() => {
    async function getProvinces() {
      try {
        setLoad(true);
        const rest = await http.getProvinces();
        const restProvince = await configResponse(rest);
        setProvinces(restProvince);
        setLoad(false);
      } catch (err) {
        setLoad(false);
      }
    }
    getProvinces();
    return () => {
      setLoad(false);
    };
  }, []);

  return { loadProvince, provinceList };
}
