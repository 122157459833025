/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import AppContext, { ActionType } from 'context';
export function Header() {
  const { pathname } = useLocation();
  const [{ isLogged }, dispatch] = React.useContext(AppContext);

  return (
    <React.Fragment>
      <div
        data-test="container"
        className="container-fluid style_MenuTop__2X0_0"
      >
        <div
          data-test="animation"
          className="animated fadeIn style_header__1unPr style_header_classic__T1L-d d-none d-lg-block"
          style={{
            animationIterationCount: 1,
            visibility: 'visible',
            animationName: 'fadeIn',
          }}
        >
          <div className="style_wrap__3OoAH">
            <div className="style_header_content__3rcVR">
              <div className="style_header_brand__27BEv">
                <Link to="/">
                  <img
                    src={require('./images/header_logo.png')}
                    alt="Bệnh viện Chợ Rẫy"
                    className="style_logoDefault__pywSz"
                  />
                </Link>
              </div>
              <nav className="style_navigator__3Iw4N">
                <div>
                  <nav
                    data-test="navbar"
                    className="navbar navbar-expand-lg style_mdbNavbar__3yMxM"
                    role="navigation"
                  >
                    <button
                      data-test="navbar-toggler"
                      type="button"
                      className="navbar-toggler style_mdbNavbarToggler__3kBAx"
                    >
                      <i className="fas fa-bars" />
                    </button>
                    <div
                      data-test="collapse"
                      id="navbarCollapse3"
                      className="collapse navbar-collapse style_mdbCollapse__30xjE"
                    >
                      <ul
                        data-test="navbar-nav"
                        className="navbar-nav justify-content-around style_mdbNavbarNav__uqTNX"
                      >
                        <li
                          className={`nav-item ${
                            pathname === '/' && 'style_active__33jzh'
                          }`}
                        >
                          <Link
                            aria-current="page"
                            className="nav-link Ripple-parent"
                            data-test="nav-link"
                            to="/"
                          >
                            Trang chủ
                            <div
                              data-test="waves"
                              className="Ripple "
                              style={{
                                top: '0px',
                                left: '0px',
                                width: '0px',
                                height: '0px',
                              }}
                            />
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            pathname === '/gioi-thieu' && 'style_active__33jzh'
                          }`}
                        >
                          <Link
                            className="nav-link Ripple-parent"
                            data-test="nav-link"
                            to="/gioi-thieu"
                          >
                            Giới thiệu
                            <div
                              data-test="waves"
                              className="Ripple "
                              style={{
                                top: '0px',
                                left: '0px',
                                width: '0px',
                                height: '0px',
                              }}
                            />
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            pathname === '/quy-trinh' && 'style_active__33jzh'
                          }`}
                        >
                          <Link
                            className="nav-link Ripple-parent"
                            data-test="nav-link"
                            to="/quy-trinh"
                          >
                            Quy trình
                            <div
                              data-test="waves"
                              className="Ripple "
                              style={{
                                top: '0px',
                                left: '0px',
                                width: '0px',
                                height: '0px',
                              }}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </nav>
              <div className="style_menu_extra__rWziE style_menu_partner_custom__3lNDA">
                <div className="style_header_info_text__1aT0W">
                  <div className="style_icons_info__3fS5A style_widget__3dM8w">
                    <div className="style_icons__2492i">
                      <img
                        src={require('./images/chat.edd95b6c.svg')}
                        alt="Support"
                      />
                    </div>
                    <div className="style_info_right__11iKh">
                      Bạn cần trợ giúp?
                      <br />
                      <a className="style_dot__2h7HY" href="tel:1900-0000">
                        1900-0000
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="style_header__1wCTp d-block d-lg-none">
        <div className="style_header__center__UsI4i">
          <a className="style_logo__3C25A" href="">
            <img
              src={require('./images/logo_header_white.svg')}
              className="style_img_hospital__TRClk"
            />
          </a>
        </div>
        <div className="style_header__right__1tNlU">
          <button
            data-test="button"
            type="button"
            className="btn-flat btn Ripple-parent style_button__bKNrt style_menu__QcEXi style_column__hnIQj"
            onClick={() =>
              dispatch({
                type: ActionType.TOGGLE_DRAW,
                payload: undefined,
              })
            }
          >
            <i className="fas fa-bars" />
            <div>Menu</div>
            <div
              data-test="waves"
              className="Ripple Ripple-outline "
              style={{ top: '0px', left: '0px', width: '0px', height: '0px' }}
            />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
Header.propTypes = {
  routers: PropTypes.array,
};
