/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import * as _ from 'lodash';
import * as AppRoutes from 'router/lazyRouting';
import { AppRouting, RoutePermissions } from 'router';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Header } from 'app/components/Header';
import { Footer } from 'app/components/Footer';
import { Drawer } from 'app/components/Drawer';
import { AnimatedSwitch } from 'react-router-transition';
import { bounceTransition } from 'animation/AnimationSwitch';
import { useTekProvince } from 'hooks/useTekProvince';
import { useSpecialList } from 'hooks/useSpecialList';
import AppContext, { ActionType } from 'context';
import { useSelector } from 'react-redux';
import * as authSelectors from 'store/auth/selectors';
import * as AuthSlice from 'store/auth/slice';
import { useInjectReducer } from 'store/core/@reduxjs/redux-injectors';
import HospitalSelector from './components/HospitalSelector';

export function App() {
  const [{ hospital, openDraw }, dispatch] = React.useContext(AppContext);
  const { specialList } = useSpecialList(hospital);
  const { provinceList } = useTekProvince();
  const [, forceUpdate] = React.useState({});

  React.useEffect(() => {
    function setInitData(provinces = []) {
      if (_.isEmpty(provinces) || !provinces.length) return;
      dispatch({
        type: ActionType.SET_PROVINCE,
        payload: provinces,
      });
    }
    setInitData(provinceList);
  }, [provinceList]);

  React.useEffect(() => {
    forceUpdate({});
    function setHospital(hospitals) {
      if (_.isEmpty(hospitals) || !hospitals.length) return;
      dispatch({
        type: ActionType.SET_HOSPITALS,
        payload: hospitals,
      });
      dispatch({
        type: ActionType.SET_HOSPITAL_ITEM,
        payload: hospitals.find((hos: any) => hos.code === 'BVCR'),
      });
    }
    setHospital(hospital);
  }, [hospital]);

  React.useEffect(() => {
    forceUpdate({});
    function setSpecialList(specials) {
      if (_.isEmpty(specialList) || !specialList.length) return;
      dispatch({
        type: ActionType.SET_SPECIALIST,
        payload: specialList,
      });
    }
    setSpecialList(specialList);
  }, [specialList]);

  const renderAppRouting = () => {
    return AppRouting.map(({ path, Component, permission }, idx) => {
      if (
        !permission ||
        permission === RoutePermissions.CLIENT ||
        permissions.includes(permission)
      )
        return <Route exact path={path} component={Component} key={idx} />;
      return (
        <Route exact path={path} key={idx}>
          <Redirect to={'/dang-nhap?a=' + path.replace('/', '')} />
        </Route>
      );
    });
  };

  // Permission
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  const permissions = useSelector(authSelectors.selectPermissions);

  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Switch>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          className="route-wrapper"
        >
          <React.Fragment>
            <div className="style_wrapper_template__kPnXT">
              <Header />
              <div style={{ minHeight: 550 }}>{renderAppRouting()}</div>
              <Footer />
              <Drawer
                visible={openDraw}
                onClose={() =>
                  dispatch({
                    type: ActionType.TOGGLE_DRAW,
                    payload: undefined,
                  })
                }
              />
            </div>
          </React.Fragment>
        </AnimatedSwitch>
      </Switch>
      <HospitalSelector />
    </SnackbarProvider>
  );
}
