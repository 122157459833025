/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Drawer as Sidebar } from 'antd';
import { useHistory } from 'react-router-dom';
import AppContext from 'context';

export function Drawer({ visible, onClose }) {
  const [{ isLogged }] = React.useContext(AppContext);
  const history = useHistory();

  const changeRoute = () => {
    history.push('/');
    onClose();
  };

  return (
    <Sidebar placement="right" visible={visible} width="400">
      <div className="side-nav wide right-aligned style_bg_menu_mobile__3ZQMe right-side-slide-enter-done">
        <div
          className="scrollbar-container ps ps--active-y"
          style={{
            overflow: window.innerWidth < 500 ? 'auto !important' : 'hidden',
          }}
        >
          <ul className="list-unstyled">
            <li>
              <div className="logo-wrapper">
                <a onClick={changeRoute} className="Ripple-parent">
                  <img
                    src="https://resource.medpro.com.vn/static/images/choray/web/logo_header_white.svg?t=85441.10044595145"
                    className="img-fluid flex-center d-block"
                  />
                  <div
                    data-test="waves"
                    className="Ripple "
                    style={{
                      top: '0px',
                      left: '0px',
                      width: '0px',
                      height: '0px',
                    }}
                  />
                </a>
              </div>
            </li>
            <li className="style_close__2BnWR">
              <button
                data-test="button"
                type="button"
                className="btn-flat btn Ripple-parent style_button__bKNrt"
                onClick={onClose}
              >
                <div>Đóng</div>
                <div
                  data-test="waves"
                  className="Ripple Ripple-outline "
                  style={{
                    top: '0px',
                    left: '0px',
                    width: '0px',
                    height: '0px',
                  }}
                />
              </button>
            </li>
            <li className="style_li_download__1fRJ6">
              <div className="style_download__2RIJU">
                <p className="style_txtDown__1vISn">Tải ứng dụng</p>
                <ul className="style_list_group__15veK">
                  <li>
                    <div className="lazyload-wrapper style_image__17kIq">
                      <a target="_blank" rel="noopener noreferrer">
                        <img
                          src={require('./images/appstore.cdb12f48.svg')}
                          alt=""
                        />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="lazyload-wrapper style_image__17kIq">
                      <a target="_blank" rel="noopener noreferrer">
                        <img
                          src={require('./images/googleplay.142f41c8.svg')}
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            {!isLogged && (
              <li className="style_li_dangnhap__3np4G">
                <button
                  onClick={() => {
                    history.push('/dang-nhap');
                    onClose();
                  }}
                  data-test="Link"
                  type="button"
                  className="btn-flat btn Ripple-parent style_button__bKNrt style_create__1hfMN style_block__3VAJ5"
                >
                  <div>Đăng nhập tài khoản</div>
                  <div
                    data-test="waves"
                    className="Ripple Ripple-outline is-reppling"
                    style={{
                      top: '0px',
                      left: '0px',
                      width: '394px',
                      height: '394px',
                    }}
                  />
                </button>
              </li>
            )}
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-home" />
                Trang chủ{' '}
              </a>
            </li>
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-info-circle" />
                Giới thiệu{' '}
              </a>
            </li>
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-cog" />
                Quy trình{' '}
              </a>
            </li>
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-book-open" />
                Hướng dẫn{' '}
              </a>
            </li>
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-question-circle" />
                Thắc mắc{' '}
              </a>
            </li>
            <li className="style_first_row__1VAY0">
              <a onClick={changeRoute}>
                <i className="fas fa-address-card" />
                Liên hệ{' '}
              </a>
            </li>
            <li>
              <a onClick={changeRoute}>
                <i className="fas fa-life-ring" />
                Hỗ trợ đặt khám: 1900-2115{' '}
              </a>
            </li>
            <li className="style_first_row__1VAY0">
              <a onClick={changeRoute}>
                <i className="fas fa-envelope" />
                Email: support@tekmedi.com{' '}
              </a>
            </li>
            <li>
              <i className="style_version__2Y7Cu">
                Phiên bản: 1.1.2     Mới nhất: 17/12/2021, 10:31
              </i>
            </li>
          </ul>
          <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: '0px', width: '0px' }}
            />
          </div>
          <div
            className="ps__rail-y"
            style={{ top: '0px', height: '736px', right: '0px' }}
          >
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: '0px', height: '708px' }}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
