/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as _ from 'lodash';
import { HttpService } from 'services/api.service';
import { configResponse } from 'utils/request';

const http = new HttpService();

export function useSpecialList(hospital: any) {
  const [loadSpecial, setLoad] = React.useState(false);
  const [specialList, setList] = React.useState([]);

  React.useEffect(() => {
    async function getSpecialList() {
      if (_.isEmpty(hospital) || hospital.code === '') return;
      try {
        setLoad(true);
        const rest = await http.getSpecialList(hospital.code);
        const restList = await configResponse(rest);
        setList(restList);
        setLoad(false);
      } catch (err) {
        setLoad(false);
      }
    }
    getSpecialList();
    return () => {
      setLoad(false);
    };
  }, [hospital]);

  return { loadSpecial, specialList };
}
